import React from 'react'
import PropTypes from 'prop-types';
import LightBox from './LightBox';

const Image = ({src, isLightBox}) => <LightBox images={[src]} isEnabled={isLightBox}><img src={src} className="responsive" /></LightBox>


Image.defaultProps = {
    isLightBox: true,
}

Image.propTypes = {
    isLightBox: PropTypes.bool, 
    src: PropTypes.any,
}

export default Image