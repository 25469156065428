import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'


import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const LIGHT_BOX_SETTINGS = {
    enableZoom:false,
    imagePadding:30,
}

class LightBox extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
    this.setIsOpen = this.setIsOpen.bind(this)
   
    this.open = this.open.bind(this)
    this.onCloseRequest = this.onCloseRequest.bind(this)
    this.setPhotoIndex = this.setPhotoIndex.bind(this)
    this.onMovePrevRequest = this.onMovePrevRequest.bind(this)
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this)
  }
 
  setIsOpen(isOpen) {
      const newState = Object.assign({}, this.state)
      newState.isOpen = isOpen
      this.setState(newState)
  }

  setPhotoIndex(photoIndex) {
    const newState = Object.assign({}, this.state)
    newState.photoIndex = photoIndex
    this.setState(newState)
  }

  open() {
    this.setIsOpen(true)
  }

  onCloseRequest() {
    this.setIsOpen(false)
  }

  get imgs() {
    return this.props.images
  }

  get isEnabled() {
      return this.imgs.length > 0 && this.props.isEnabled
  }

  get ind () {
    return this.state.photoIndex
  }

  get mainSrc() {
    return this.imgs[this.ind]
  }

  get prevIndex() {
    return (this.ind + this.imgs.length - 1) % this.imgs.length
  }

  get nextIndex() {
    return (this.ind + 1) % this.imgs.length
  }

  src(i) {
    return this.imgs.length > 1 ? this.imgs[i] : null
  }

  get nextSrc() {
    return this.src(this.nextIndex)
  }

  get prevSrc() {
    return this.src(this.prevIndex)
  }

  onMovePrevRequest() {
    this.setPhotoIndex(this.prevIndex)
  }

  onMoveNextRequest() {
    this.setPhotoIndex(this.nextIndex)
  }

  render() {
    const { isOpen } = this.state
    const { mainSrc, nextSrc, prevSrc, onCloseRequest,onMovePrevRequest, onMoveNextRequest} = this
    const { children } = this.props

    if (!this.isEnabled) {
        return <Fragment>{children}</Fragment>
    }
    return (
        <Fragment>
            <div onClick={this.open} style={{cursor: 'pointer'}}> 
                {children}
            </div>
            {isOpen && (
                    <Lightbox
                        mainSrc={mainSrc}
                        nextSrc={nextSrc}
                        prevSrc={prevSrc}
                        onCloseRequest={onCloseRequest}
                        onMovePrevRequest={onMovePrevRequest}
                        onMoveNextRequest={onMoveNextRequest}
                        {...LIGHT_BOX_SETTINGS}
                    />
                    )}
        </Fragment>
    )
  }
}

LightBox.defaultProps = {
    images: [],
    isEnabled: true,
}

LightBox.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string.isRequired),
    isEnabled: PropTypes.bool,
}

export default LightBox